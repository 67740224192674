$primaryColor: #f8b134;		//svetlo-oranzova
$secondaryColor: #565655;	//siva
$tertiaryColor: #ffffff;		//biela
$quaternaryColor: red;	//
$pentiaryColor: green;		//

$errorColor: #cc0000;
$warningColor: #ffcc00;
$successColor: #33cc00;

$body-font-family: 'Ubuntu', sans-serif;
$base-font-size: 1rem;
$base-line-height: 1.5rem;


$gutter: 15px;
