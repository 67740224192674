@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

body {
	> footer {
		background-color: $secondaryColor;
		background-image: url('../images/layout/footer-bg.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: cover;
		color: $tertiaryColor;
		a {
			color: inherit;
			&:hover {
				color: none; } }
		section {
			&:first-child {
				background-color: $secondaryColor; } }
		nav {
			ul {
				list-style: none;
				color: $tertiaryColor;
				font-size: bold;
				text-transform: uppercase;
				text-align: center;
				margin-top: 10px;
				a {
					color: inherit;
					background: none !important;
					padding: .7rem 2rem;
					display: block; }
				li {
					display: table-cell;
					float: left; }
				ul {
					display: none; } }
			ul:first-child {
				> li {
					> a {
						border-top: 6px solid transparent;
						background-color: transparent; }
					&.active,
					&:hover {
						> a {
							border-top: 6px solid $primaryColor; } } } } }

		.logo {
			margin-top: 45px; }
		h3 {
			margin-top: 45px;
			color: $primaryColor;
			text-transform: uppercase; }
		.hr {
			hr {
				margin-top: 60px;
				border-color: #5e5e5e; } }
		.disclaimer {
			span {
				color: $tertiaryColor; }
			p {
				margin-top: 1rem; } }
		.company {
			color: white; }
		.sixnet {
			color: #75bfea; }
		.siete {
			font-size: 45px;
			a {
				color: $tertiaryColor;
				&:hover {
					color: $primaryColor; } } }
		.icon-prev {
			&:before {
				color: $primaryColor; } }
		.operation {
			a {
				color: inherit; }
			span {
				&:first-child {
					/*font-weight: bold*/ } } }
		.bill-info {
			span {
				text-transform: uppercase; } }

		[role="copyright"] {
			font-size: 0.75em;
			text-align: center; } } }



.tile {
	width: 75%;
	margin: 0 auto;
	text-align: center;
	border-top: 9px solid $secondaryColor;
	position: relative;
	display: table;
	top: -110px;
	z-index: 101;
	li {
		display: table-cell;
		a {
			display: block;
			background-image: url('../images/layout/logo-tile.png');
			background-repeat: no-repeat;
			background-position: center;
			width: 100%;
			height: 330px;
			background-color: $tertiaryColor;
			padding-top: 280px;
			color: $secondaryColor;
			font-weight: bold;
			&:hover {
				background-color: $primaryColor;
				background-image: url('../images/layout/logo-tile-white.png');
				color: $tertiaryColor; } } } }

