@CHARSET 'UTF-8' {}

@import '../variables';
@import '../mixins';

h2 {
	text-transform: uppercase;
	text-align: center;
	margin-top: 3 * $gutter;
	margin-bottom: $gutter;
	font-size: 1.5rem; }
//h3
//ext-transform: uppercase
//ext-align: center
//argin-top: 3 * $gutter
//argin-bottom: $gutter



.references {
	background-color: $secondaryColor;
	color: $tertiaryColor;
	h2 {
		span {
			display: inline-block;
			&:before {
				content: '';
				display: block;
				width: 80%;
				height: 6px;
				margin: 0px auto;
				background-color: $primaryColor; } } } }

