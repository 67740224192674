.reference_item {
	img {
		border: 4px solid #656565; }
	h4 {
		font-size: 17px;
		font-weight: bolder;
		text-transform: uppercase;
		margin-bottom: 0;
		margin-top: 0.5em;
		a {
			color: white !important; } }
	p {
		color: white !important; } }
.item {
	margin-bottom: 25px;
	border: 2px solid #f8b134; }
