@mixin box-shadow($shadow) {
	-webkit-box-shadow: $shadow;
	-moz-box-shadow: $shadow;
	-o-box-shadow: $shadow;
	box-shadow: $shadow; }

@mixin border-radius($radius: 5px) {
	-webkit-border-radius: $radius;
	-moz-border-radius:    $radius;
	-ms-border-radius:     $radius;
	border-radius:         $radius; }

@mixin scale($scale: 1.15) {
	transform:          scale($scale);
	-webkit-transform:  scale($scale);
	-moz-transform:     scale($scale);
	-ms-transform:      scale($scale);
	-o-transform:       scale($scale); }

@mixin verticalAlign($position: relative) {
	position: $position;
	top: 50%;
	transform: translateY(-50%);
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%); }

@mixin transition($property: all, $duration: 0.25s, $timing-function: ease-in-out) {
	transition:         $property $duration $timing-function {
    -webkit-transition: $property $duration $timing-function;
    -moz-transition:    $property $duration $timing-function;
    -ms-transition:     $property $duration $timing-function;
    -o-transition:      $property $duration $timing-function; } }
