@CHARSET 'UTF-8' {}

@import 'variables';
@import 'mixins';

@import url('https://fonts.googleapis.com/css?family=Khand:300,400,500,600,700');

.menu .active>a {
	background: transparent; }

[class^="fi-"]::before,
[class*=" fi-"]::before {
    margin-right: 0;
    margin-left: 0; }

h1 {
	font-family: Khand,sans-serif; }
a {
	color: #445276;
	text-decoration: none;
	&:hover {
		color: lighten($primaryColor, 20);
		transition: width 2s; }
	img {
		border: none; } }


nav {
	ul {
		list-style: none; } }

body {
	background-color: $tertiaryColor;
	font-family: 'Khand', sans-serif;
	h3 {
		font-family: 'Khand', sans-serif; }

	> header {
		background-color: $tertiaryColor;
		section {
			position: relative; }
		.top-strip {
			background-color: $secondaryColor;
			color: $primaryColor;
			font-size: 0.75rem; }
		nav.main-navigation-0 {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1000;
			padding-top: 15px;
			padding-bottom: 15px;
			ul {
				list-style: none;
				color: $secondaryColor;
				font-size: bold;
				text-transform: uppercase;
				table-layout: auto !important;
				li {
					position: relative;
					white-space: nowrap;
					background-color: transparentize($tertiaryColor, 0.25);
					a {
						color: inherit;
						background: none !important; }
					&.active {
						a {
							color: inherit; } }
					ul {
						display: none;
						position: absolute;
						margin: 0;
						text-transform: none;
						li {
							float: none;
							a {
								padding: 5px;
								color: inherit; }
							&:hover {
								background-color: transparentize($tertiaryColor, 0.75);
								a {
									color: $secondaryColor; } } } }
					&:hover {

						> ul {
							display: block; }
						> a {
							background-color: transparent;
							color: darken($secondaryColor, 10); } } } }
			ul:first-child {
				> li {
					> a {
						background-color: transparent;
						border-top: 6px solid transparent; }
					&:hover,
					&.active {
						> a {
							border-top: 6px solid $primaryColor; } } } } }


		section[role="slide"] {
			background-color: $secondaryColor; } }




	> main {
		section {
			&.about-us {
				padding-top: $gutter * 1.5;
				padding-bottom: $gutter * 2; } } } }

.disclaimer {
	&:hover {
		color: white !important; } }



.cycle-slideshow {
	display: block;
	margin: 0;
	width: 100%;
	height: 100%;
	.cycle-slide {
		width: 100%;
		height: 100%;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
		.slide-text {
			height: 100%;
			padding: $gutter 0; } } }

.cycle-control {
	font-size: $base-font-size * 3;
	color: $primaryColor;
	position: absolute;
	top: 50%;
	z-index: 1000;
	cursor: pointer;
	&#prev {
		left: 30px; }
	&#next {
		right: 30px; } }








.sm-simple {
	border: 0 none;
	background: none;
	@include box-shadow('none'); }

@media (min-width: 768px) {
	#main-menu > ul > li {
		float: none;
		display: table-cell;

		text-align: center; } }






.main-nav {
	position: relative;
	top: 0;
	left: 0;
	z-index: 1000;
	padding-top: 15px;
	padding-bottom: 15px;
	width: 100%;
	background-color: $tertiaryColor;
	&:after {
		clear: both;
		content: "\00a0";
		display: block;
		height: 0;
		font: 0px/0 serif;
		overflow: hidden; } }
nav#main-menu ul.sm.sm-simple > :nth-child(3) >a {
	font-weight: 600; }
nav#main-menu ul.sm.sm-simple > :nth-child(3) {
	&:after {
		content: '';
		top: -5px;
		background-image: url(../images/polygon.png);
		position: relative;
		background-repeat: no-repeat;
		height: 5px;
		display: block;
		left: 80px; } }
nav#main-menu ul.sm.sm-simple > :nth-child(2) >a {
	font-weight: 600; }
nav#main-menu ul.sm.sm-simple > :nth-child(2) {
	&:after {
		content: '';
		top: -5px;
		background-image: url(../images/polygon.png);
		position: relative;
		background-repeat: no-repeat;
		height: 5px;
		display: block;
		left: 110px; } }
.nav-brand {
	float: left;
	margin: 0;
	a {
		display: block;
		/* padding: 11px 11px 11px 20px */
		color: #555;
		font-size: 20px;
		font-weight: normal;
		line-height: 17px;
		text-decoration: none;
		height: 60px;
		img {
			height: 100%;
			width: auto; } } }
@media (min-width: 768px) {
	.nav-brand {
		a {
			/* height: 70px */
			margin-top: 20px; } } }

#main-menu > ul {
	clear: both;
	border: 0;
	-webkit-box-shadow: none;
	-moz-box-shadow: none;
	box-shadow: none;
	display: none;
	li {}

	> li > a {
		&:hover {
			color: #7ed1ff; }

		color: #dedfe0; } }


@media (min-width: 768px) {
	.main-nav {
		background-color: transparent; }
	#main-menu > ul {
		clear: none;
		display: block;
		> li > a > .sub-arrow {
			display: none; } } }


/* Mobile menu toggle button */

.main-menu-btn {
	float: right;
	margin: 5px 10px;
	position: relative;
	display: inline-block;
	width: 29px;
	height: 29px;
	text-indent: 29px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0); }


/* hamburger icon */

.main-menu-btn-icon,
.main-menu-btn-icon:before,
.main-menu-btn-icon:after {
	position: absolute;
	top: 50%;
	left: 2px;
	height: 2px;
	width: 24px;
	background: #555;
	-webkit-transition: all 0.25s;
	transition: all 0.25s; }

.main-menu-btn-icon:before {
	content: '';
	top: -7px;
	left: 0; }

.main-menu-btn-icon:after {
	content: '';
	top: 7px;
	left: 0; }

/* x icon */

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon {
	height: 0;
	background: transparent; }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before {
	top: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg); }

#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after {
	top: 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg); }

/* hide menu state checkbox (keep it visible to screen readers) */

#main-menu-state {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	border: 0;
	padding: 0;
	overflow: hidden;
	clip: rect(1px, 1px, 1px, 1px); }

/* hide the menu in mobile view */

#main-menu-state:not(:checked) ~ #main-menu {
	display: none; }

#main-menu-state:checked ~ #main-menu {
	display: block; }

@media (min-width: 768px) {
	/* hide the button in desktop view */
	.main-menu-btn {
		position: absolute;
		top: -99999px; }
	/* always show the menu in desktop view */
	#main-menu-state:not(:checked) ~ #main-menu {
		display: block; } }












ul#lang {
	list-style: none;
	margin: 0;
	*zoom: 1;
	li {
		float: left;
		visibility: collapse;
		a {
			display: block;
			padding: 3px 10px;
			visibility: visible; } }
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; }
	&.flag {
		a {
			width: 2rem;
			height: 1.5rem;
			background-size: contain;
			background-position: 50%;
			background-repeat: no-repeat; } }
	&.code {
		a {
			&:before {
				content: attr(data-code);
				text-transform: uppercase; } } }
	&.name {
		a {
			&:before {
				content: attr(title); } } } }



.relative {
	position: relative; }

.align-center {
	text-align: center; }
.middle {
	@include verticalAlign(); }


.bold {
	font-weight: bold; }
.uppercase {
	text-transform: uppercase; }


/* line break after element */
.br {
	&:after {
		content: '\A';
		white-space: pre; } }

/* clear-fix */
.cf {
	*zoom: 1;
	&:before,
	&:after {
		content: ' ';
		display: table; }
	&:after {
		clear: both; } }

@media screen and (max-width: 1024px) {
	.cf-medium {
		*zoom: 1;
		&:before,
		&:after {
			content: ' ';
			display: table; }
		&:after {
			clear: both; } } }
@media screen and (min-width: 1025px) {
	.small-0 {
		width: 0; } }




/* proportional height */
#testProportional {
	margin: 50px auto;
	width: 300px;
	background-color: green; }

.crust {
	position: relative;
	width: 100%;
	height: auto;
	overflow: hidden;
	&:before {
		content: '';
		display: block;
		padding-top: 100%; }
	.core {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		text-align: center;
		img,
		.content {
			max-width: 100%;
			max-height: 100%;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto; }
		.content {
			width: 100%;
			height: 100%; } }
	&.cover .core img {
		/*width: 100%*/
		/*height: auto*/
		/*max-height: none*/
		min-width: 100%;
		min-height: 100%;
		height: auto;
		max-width: none; }
	&.ratio-2_1:before {
		padding-top: 50%; }
	&.ratio-4_3:before {
		padding-top: 75%; }
	&.ratio-16_9:before {
		padding-top: 56.25%; }
	&.ratio-9_4:before {
		padding-top: 44.44%; }
	&.ratio-5_4:before {
		padding-top: 80%; }
	&.ratio-1_2:before {
		padding-top: 200%; }
	&.ratio-4_5:before {
		padding-top: 125%; } }

/* icon before content */
a.icon-prev {
	&:hover {
		color: lighten($primaryColor, 20); } }
.icon-prev {
	position: relative;
	display: inline;
	margin-left: 2.25em;
	white-space: nowrap;
	&:before {
		position: absolute;
		margin-top: 0.15em;
		left: -1.20em;
		font-size: 1.10em;
		color: $quaternaryColor; }
	&:first-child {
		margin-left: 1.35em; } }





/* title after icon */
.tai {
	position: relative;
	white-space: nowrap;
	color: $primaryColor;
	font-size: $base-font-size;
	line-height: $base-line-height;
	margin-right: $base-font-size;
	&:after {
		content: attr(data-title);
		position: relative;
		display: inline-block;
		font-size: $base-font-size * 0.75;
		line-height: $base-line-height;
		margin-left: $base-font-size * 0.33; }
	&:before {
		line-height: $base-line-height; } }


@media screen and (max-width: 991px) {

	.tai {
		cursor: help;
		font-size: $base-font-size * 2.5;
		&:after {
			display: none; }
		&:hover:after,
		&:focus:after,
		&:active:after {
			display: block;
			position: absolute;
			top: $base-line-height * 0.5;
			left: 100%;
			z-index: 1000;
			background-color: transparentize($tertiaryColor, 0.15);
			font-size: $base-font-size;
			line-height: $base-line-height;
			padding: 1px 4px;
			@include border-radius(2px); }
		&:before {
			line-height: $base-line-height * 2.5; } }
	a.tai {
		cursor: pointer; } }



label {
	.req::after {
		content: '*';
		color: $errorColor;
		font-weight: bold;
		margin-left: 0.3rem {
	    margin-right: 1.2rem; } } }

ul.evenly {
	text-align: justify;
	min-width: 500px;
	&:after {
		content: '';
		display: inline-block;
		width: 100%; }
	li {
		display: inline-block; } }

.fancybox {
	cursor: zoom-in; }


/* ADMIN: edit text on the page */
.admin-edit {
	position: relative;
	border: 1px dashed transparentize($warningColor, 0.75);
	background-color: transparentize(white, 0.95);
	&:after {
		content: '';
		display: block;
		position: absolute;
		top: 0px;
		left: 0px;
		right: 0px;
		bottom: 0px;
		border: 1px solid transparentize(darken($warningColor, 30), 0.75);
		background-color: transparentize(black, 0.95);
		&:hover {
			display: none; } }
	&:hover {
		border: 1px solid darken($errorColor, 10);
		background-color: transparentize(white, 0.33);
		color: darken($errorColor, 20);
		cursor: url(../images/pencil-red-16.gif) 0 16, pointer; }
	&[data-variant="static_content"] {
		display: inherit;
		&:hover {
			cursor: url(../images/doc-edit-16.gif) 0 16, pointer; } } }


/*molino*/
.top-menu {
	margin-top: 30px; }
.orderer {
	display: block;
	background-color: #4fbf74;
	border-radius: 8px;
	height: 40px;
	font-size: 18px;
	text-align: center;
	padding-top: 7px;
	margin-top: 30px;
	width: 140px;
	float: left !important;
	margin-left: 40px;
	margin-top: 43px;
	border: 2px solid #4fbf74;
	&:hover {
		background-color: transparent;
		border: 2px solid #4fbf74; }
	a {
		display: block !important;
		color: white !important; }
	&:hover {
		a {
			color: #4fbf74 !important;
			transition: width 2s; } } }
h2.nav-brand {
	text-align: left; }

.left_box {
	position: relative;
	top: 25px;
	background-image: url(../images/header_service_data.jpg);
	background-repeat: no-repeat;
	background-size: 96%;
	padding: 100px 40px 75px 40px;
	color: white;
	h3 {
		font-size: 45px;
		margin-left: 10px; }
	p {
		font-size: 22px;
		margin-bottom: 50px;
		font-weight: 300;
		margin-left: 10px; }
	ul.sm.sm-simple {
		a {
			color: white;
			text-decoration: underline;
			font-size: 18px;
			box-shadow: none !important;
			-webkit-box-shadow: none !important;
			&:hover {
				color: #7ed1ff; } } } }
.need {
	background-color: #fff;
	display: inline-block;
	height: 60px;
	border-radius: 8px;
	text-align: center;
	padding: 15px;
	font-size: 22px;
	margin-bottom: 50px;
	margin-left: 10px;
	border: 2px solid #fff;
	&:hover {
		color: white;
		background-color: transparent;
		border: 2px solid white;
		transition: width 2s; } }
.right_box {
	position: relative;
	top: 25px;
	background-image: url(../images/header_service_translation.jpg);
	background-repeat: no-repeat;
	background-size: 96%;
	padding: 100px 40px 75px 40px;
	color: white;
	h3 {
		font-size: 45px;
		margin-left: 10px; }
	h1 {
		font-size: 45px;
		margin-left: 10px;
		font-family: Khand,sans-serif; }
	p {
		font-size: 22px;
		margin-bottom: 50px;
		font-weight: 300;
		margin-left: 10px; }
	ul.sm.sm-simple {
		a {
			color: white;
			text-decoration: underline;
			font-size: 18px;
			&:hover {
				color: #7ed1ff; } } } }
.industries {
	text-align: center;
	color: #406197;
	font-size: 45px;
	margin-top: 50px;
	text-transform: uppercase; }
p.solution {
	text-align: center;
	font-size: 22px;
	color: #858585;
	margin-bottom: 0; }
.semi {
	font-weight: 500;
	margin-bottom: 1rem !important; }
.gr {
	a {
		color: white;
		line-height: 1.2;
		display: block;
		padding-top: 80px;
		padding-bottom: 80px; } }
.boxs {
	padding: 1px; }
.boxs_2 {
	padding: 1px; }
.group_1 {
	background-image: url(../images/button_administration.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_2 {
	background-image: url(../images/button_education.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_3 {
	background-image: url(../images/button_engineering.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_4 {
	background-image: url(../images/button_finance.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_5 {
	background-image: url(../images/button_government.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_6 {
	background-image: url(../images/button_insurance.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_7 {
	background-image: url(../images/button_legal.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_8 {
	background-image: url(../images/button_medical.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_9 {
	background-image: url(../images/button_migration.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_10 {
	background-image: url(../images/button_personal_documents.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 70px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	p {
		line-height: 1.2; }
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_11 {
	background-image: url(../images/button_sport.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.group_12 {
	background-image: url(../images/button_telecommunication.svg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 190px;
	text-align: center;
	/* padding-top: 80px */
	color: white;
	font-size: 24px;
	margin-right: 1px;
	&:hover {
		opacity: 0.9;
		filter: alpha(opacity=90); } }
.back {
	background-image: url(../images/white.png);
	background-repeat: no-repeat;
	background-size: cover;
	height: 410px;
	margin-top: -35px;
	h3 {
		color: #6d6d6d;
		font-size: 45px;
		margin-top: 100px;
		padding-left: 100px;
		span {
			font-weight: 600; } } }
.best {
	p {
		color: #8e8e8e;
		font-size: 22px;
		padding-left: 100px;
		h3 {} } }


.fast {
	text-align: center;
	a {
		color: white;
		background-color: #4fbf74;
		display: inline-block;
		margin-top: 170px;
		font-size: 22px;
		height: 60px;
		padding: 15px;
		width: 180px;
		text-align: center;
		border-radius: 8px;
		border: 2px solid #4fbf74;
		&:hover {
			background-color: #f7f7f7;
			border: 2px solid #4fbf74;
			color: #4fbf74;
			transition: width 2s; } } }
.back_2 {
	background-color: white;
	width: 100%; }
.row.gr {
	/* margin-top: 2px */ }
.clients {
	width: 70%;
	/* height: 335px */
	background-color: #b2daf1;
	margin: 0 auto;
	position: relative;
	top: -40px;
	color: #406197;
	text-align: center;
	padding-left: 80px;
	padding-right: 80px;
	h3 {
		font-size: 45px;
		color: #406197;
		text-transform: uppercase;
		text-align: center;
		padding-top: 50px; }
	p {
		display: inline-block;
		padding: 5px;
		font-size: 18px;
		margin-bottom: 0px; }
	h4 {
		font-size: 25px;
		font-family: Khand,sans-serif;
		margin-top: 15px;
		padding-bottom: 35px;
		span {
			font-weight: 600; } } }
.step_1 {
	background-image: url(../images/logo_clients_embassy_svk.jpg);
	background-repeat: no-repeat;
	height: 120px; }
.step_2 {
	background-image: url(../images/logo_clients_mzv.jpg);
	background-repeat: no-repeat;
	height: 120px; }
.step_3 {
	background-image: url(../images/logo_clients_internal_affairs_taiwhenua.jpg);
	background-repeat: no-repeat;
	height: 120px; }
.step_4 {
	background-image: url(../images/logo_clients_australia_government.jpg);
	background-repeat: no-repeat;
	height: 120px; }

.top-header {
	background-image: url(../images/molino_image.jpg);
	background-repeat: no-repeat;
	background-size: cover; }
.blue {
	width: 35%;
	height: 520px;
	background-color: #377cbd;
	float: left; }
.town {
	background-image: url(../images/footer_bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	width: 65%;
	height: 520px;
	float: left; }
.login {
	background-image: url(../images/logo_molino_consulting_footer.png);
	background-repeat: no-repeat;
	background-position: 10% 40%;
	height: 500px; }
.into {
	display: inline-block;
	float: left; }
.boat {
	width: 70%;
	height: 500px;
	margin: 0 auto;
	position: absolute;
	left: 15%; }
.contacts {
	h3 {
		text-transform: capitalize;
		font-size: 35px;
		color: white; }
	a {
		color: #7acaf7;
		display: block;
		margin-bottom: 30px;
		font-size: 18px; }
	p {
		line-height: 1;
		font-size: 18px; } }
.targ {
	color: #7acaf7;
	margin-bottom: 35px; }
.form {
	h3 {
		font-size: 35px;
		text-transform: uppercase;
		color: white; } }
p.aust {
	float: left; }
p.ph {
	float: left;
	padding-left: 5px; }
p.euro {
	float: left; }
.molin {
	margin-top: 40px; }
.in {
	background-image: url(../images/social_linkedin.svg);
	background-repeat: no-repeat;
	background-position: 100%;
	width: 40px;
	height: 30px;
	display: inline-block;
	margin-left: -5px; }
.twitter {
	background-image: url(../images/social_twitter.svg);
	background-repeat: no-repeat;
	background-position: 100%;
	width: 40px;
	height: 30px;
	display: inline-block; }
footer {
	position: relative;
	width: 100%;
	font-family: 'Khand', sans-serif; }
input {
	width: 100% !important;
	background-color: transparent !important;
	margin-bottom: -1px !important;
	float: left;
	color: white !important;
	height: 2.9375rem !important;
	&:focus {
		border: 1px solid white !important; } }
input[type="submit"] {
	width: 100px !important;
	height: 40px;
	float: right;
	background-color: #7ed1ff !important;
	border-radius: 8px !important;
	border-width: 0px;
	font-size: 18px;
	&:hover {
		color: #7ed1ff !important;
		background-color: transparent !important;
		border: 2px solid #7ed1ff; } }
textarea {
	width: 100% !important;
	height: 140px !important;
	background-color: transparent !important;
	float: right;
	color: white;
	&:focus {
		border: 1px solid white !important; } }
iframe {
	width: 100%; }
.design {
	text-align: center;
	background-color: #39485f;
	font-size: 18px;
	height: 40px;
	padding-top: 7px;
	p {
		margin-bottom: 0; } }
.menu {
	height: 100px;
	padding-top: 30px;
	ul {
		li {
			a {
				color: white; } } } }
nav#main-menu {
	background-repeat: no-repeat;
	background-size: cover; }
.sm-simple > li {
	border-left: 0; }

section#slider {
	height: 805px !important; }
.reading {
	height: 1000px;
	background-color: #324158;
	color: white; }

.right-read {
	margin-top: 15px; }
.left-part {
	float: left;
	width: 42%; }
.translation {
	width: 50%;
	float: left; }
.language {
    width: 50%;
    float: left; }
.proof {
	width: 50%;
	float: left; }
.consult {
	width: 50%;
	float: left; }
.interpretation {
	width: 50%;
	float: left; }
.right-part.medium-6.column {
    height: 620px; }
.natti {
	margin-left: 170px; }
.people {
	background-image: url(../images/people.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	height: 100%; }
.people img {
	top: -110px;
	position: relative;
	left: 50%; }
.flow {
	width: 100%;
	height: 400px;
	background-color: rgba(187,211,243,0.65);
	position: relative;
	top: 100px; }
.flow p {
	color: black;
	text-align: center;
	font-size: 29px; }
.icons {
	background-color: #cde3f9;
	height: 640px;
	width: 60%;
	float: left; }
.img-first-line {
	text-align: center; }
.img-secound-line {
	text-align: center; }
.img-thirth-line {
	text-align: center; }
.img-four-line {
	text-align: center; }
.top {
	margin-top: 100px; }
.logs {
	font-size: 20px;
	text-transform: lowercase;
	margin-top: 15px; }
.line {
	height: 30px;
	background-color: #324158; }
.clear {
	clear: both; }
.business {
	background-image: url(../images/business.jpg);
	background-size: cover;
	height: 100%;
	background-repeat: no-repeat; }
.our {
	width: 100%;
	height: 100px;
	background-color: rgba(187,211,243,0.65);
	position: relative;
	top: 100px; }
.our {
	h2 {
		margin-top: 0;
		font-size: 63px;
		color: white;
		text-transform: lowercase;
		padding-right: 300px; } }
#footer {
	background-color: #324158;
	height: 100%; }
.app {
	img {
		float: right; } }
.tpp {
	margin-left: 30px; }
.thp {
	width: 45%;
	background-color: #377dbd;
	height: 85%;
	/* position: absolute */
	margin-top: 15px;
	float: left;
	img {
		position: relative;
		top: 150px;
		left: 530px; } }
.ltt {
	padding-top: 10px; }
form {

	margin-top: 20px;
	width: 100%;
	margin-bottom: 20px;
	::-webkit-input-placeholder/* Chrome/Opera/Safari */ {
		color: white; }
	::-moz-placeholder /* Firefox 19+ */ {
		color: white; }
	-ms-input-placeholder/*: IE 10+ */ {
		color: white; }
	-moz-placeholder/*: Firefox 18- */ {
		color: white; } }
.button {
	float: right;
	margin-right: 10px;
	background-color: white !important;
	color: #555555; }
input {
	border-radius: 0px !important; }
textarea {
	border-radius: 0px !important; }
.text-right {
	padding-right: 15px; }
.special_2 {
	color: white;
	margin-top: 50px;
	h1 {
		color: white;
		text-align: center;
		text-transform: uppercase;
		font-size: 45px;
		font-family: Khand,sans-serif; }
	p {} }

.aline {
	text-align: center; }
.pagediv {
	background-color: white;
	width: 100%;
	margin: 0 auto;
	padding: 45px 40px 45px 30px;
	margin-bottom: 100px;
	color: #999999;
	font-size: 18px;
	p {
		line-height: 1.2; } }
.brown {
	text-align: left;
	font-size: 20px; }
.brown_2 {
	font-size: 18px; }
.brown_3 {
	margin-left: 20px;
	font-size: 18px; }
.brown_4 {
	font-size: 18px;
	span {
		color: #a6deff; } }
.shape {
	background-image: url(../images/shape.png);
	background-repeat: no-repeat;
	height: 65px;
	width: 2px;
	float: left; }
/* responzive */

@media screen and (min-width: 1210px) {
	.row-special {
		margin-left: 25px; } }

@import 'footer';

@import 'modules/contact';
@import 'modules/home';
@import 'modules/article';

/* responzive */
@media screen and (max-width: 1250px) {
	.pagediv {
		width: 90%; } }

@media screen and (max-width: 1210px) {
	.special {
		margin-left: 25px; }
	.left_box, .right_box {
		background-size: 96% 96%;
		min-height: 631px; } }


@media screen and (max-width: 1150px) {
	.top-menu {
		width: 80%; } }
@media screen and (max-width: 1100px) {
	.login {
		background-size: 80%; } }
@media screen and (max-width: 949px) {
	.left_box, .right_box {
		min-height: 666px; } }

@media screen and (max-width: 940px) {
	.contacts {
		p {
			font-size: 15px; }
		a {
			font-size: 15px; } }
	[type=text], [type=password], [type=date], [type=datetime], [type=datetime-local], [type=month], [type=week], [type=email], [type=number], [type=search], [type=tel], [type=time], [type=url], [type=color], textarea {
		font-size: 15px; }
	input[type=submit] {
		font-size: 15px; }
	.row.feet {
		margin-left: -50px;
		margin-right: -50px; }
	.design {
		font-size: 15px; }
	.step_1, .step_2, .step_3, .step_4 {
		background-position: 50%;
		background-size: 100%; } }
@media screen and (max-width: 816px) {
	.sm-simple a, .sm-simple a.highlighted, .sm-simple a:active, .sm-simple a:focus, .sm-simple a:hover {
		padding: 11px 5px !important; } }
@media screen and (max-width: 767px) {
	.special {
		margin-top: 50px; }
	.left_box, .right_box {
		background-size: 96% 96%; }
	#main-menu>ul>li>a {
		color: #445276; }
	.sm-simple a, .sm-simple a.highlighted, .sm-simple a:active, .sm-simple a:focus, .sm-simple a:hover {
		font-size: 15px !important; }
	.left_box ul.sm.sm-simple a {
		font-size: 18px !important; }
	.right_box ul.sm.sm-simple a {
		font-size: 18px !important; } }
@media screen and (max-width: 670px) {
	.gr a, .group_1, .group_10, .group_11, .group_12, .group_2, .group_3, .group_4, .group_5, .group_7, .group_8, .group_9 {
		font-size: 22px; } }
@media screen and (max-width: 639px) {
	.blue {
		width: 100%; }
	.town {
		width: 100%; }
	.boat {
		height: 110px; }
	.login {
		height: 110px;
		background-size: initial;
		background-position: 50%; }
	.contacts {
		text-align: center; }
	p.aust {
		float: none; }
	p.ph {
		float: none; }
	p.euro {
		float: none; }
	.form {
		h3 {
			text-align: center; } }
	.group_1, .group_10, .group_11, .group_12, .group_2, .group_3, .group_4, .group_5, .group_7, .group_8, .group_9, .group_10, .group_11, .group_6 {
		background-size: 100% 100%; }
	.step_1, .step_2, .step_3, .step_4 {
		background-size: inherit; }
	.fast a {
		margin-top: 0; }
	.contacts {
		h3 {
			margin-top: 20px; }
		p {
			margin-top: 20px; }
		a {
			margin-bottom: 20px; } }
	.into {
		float: none; }
	.in {
		margin-left: 0;
		background-position: 0; }
	.twitter {
		background-position: 0; } }


@media screen and (max-width: 639px) {
	.clients {
		top: 0;
		margin-top: 50px;
		padding-left: 10px;
		padding-right: 10px; }
	.back {
		height: auto;
		h3,p {
			padding-left: 0;
			text-align: center; } }
	.fast a {
		margin-bottom: 15px; }
	.login {
		margin-top: 15px; } }
@media screen and (max-width: 979px) {
	.sm-simple a, .sm-simple a:hover, .sm-simple a:focus, .sm-simple a:active, .sm-simple a.highlighted {
		padding: 11px 8px;
		font-size: 15px; } }
@media screen and (max-width: 767px) {
	nav#main-menu {
		background-color: transparent;
		ul.sm.sm-simple {
			background-color: white; } }
	.top-menu {
		width: 100%;
		margin-top: 0; }
	.orderer {
		display: none; }
	#main-menu-state {
		height: 0; }
	label.main-menu-btn {
		position: absolute;
		top: 15px;
		right: 15px; }
	.special {
		margin-top: 0; } }
@media screen and (max-width: 425px) {
	.need {
		height: auto; }
	nav#main-menu {
		background-color: transparent;
		ul.sm.sm-simple {
			background-color: white; } }
	.top-menu {
		width: 100%;
		margin-top: 0; }
	.orderer {
		display: none; }
	#main-menu-state {
		height: 0; }
	label.main-menu-btn {
		position: absolute;
		top: 15px;
		right: 15px; }
	.special {
		margin-top: 0; } }


@media screen and (max-width: 333px) {
	.boat {
		left: 14%; } }
